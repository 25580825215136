.data-extraction-container {
  width: 100%;
  gap: 16px;
  padding: 16px;
  .data-extraction-paper {
    .data-extraction-content {
      display: flex;
      flex-direction: column;
      padding: 24px 16px;
      gap: 24px;
      max-width: 600px;
      margin: 0 auto;
      .data-extraction-inputs {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
        .data-extraction-inputs-row {
          display: flex;
          gap: 16px;
        }
        .data-extraction-input-container {
          width: 100%;
          min-height: 42px;
          .data-extraction-input-label {
            font-size: 14px;
            color: #000;
            margin-bottom: 4px;
          }
        }
        .custom-select-container {
          margin: 0;
        }
      }
      .button-container {
        display: flex;
        justify-content: flex-end;
        button {
          max-height: 35px;
          font-size: 14px;
        }
      }
    }
  }
}
